
@font-face {
  font-family: 'Roboto'; 
  font-style: normal;
  font-weight: normal;
  src: local('Roboto'), url('./fonts/Roboto-Regular.ttf') format('truetype');
}

body{
  font-family: Roboto;
}

.footer {
  width: 100%;
  height: 88px;

  background: var(--light-blue);
  margin-top: 20px;
  font-size: 20px;
  color: var(--shadow-white);
}

.wrapper {
  min-height: calc(100vh - 216px);
}

:root { 
  /* Colors */
  
  --light-blue:       #2196F3;
  --light-blue-1:     rgba(33, 150, 243, .05);
  --light-blue-2:     rgba(33, 150, 243, .15);
  --light-blue-3:     rgba(33, 150, 243, .30);

  --light-grey:       #E9EDF5;
  --grey:             #4F4F4F;
  --grey-1:           rgba(247, 249, 252, 0.8);
  --grey-2:           #464F60;
  --grey-3:           rgba(79, 79, 79, .5);
  --dark-grey:        #333333;
  --black-1:          #222834;
  --light-green:      #E1FCEF;
  --green:            #14804A;
  --red:              #D1293D;
  --red-1:            #EB5757;
  --light-red:        #FFEDEF;
  --shadow-white:     #F2F2F2;
  --dark-blue:        #0276D2;


   /* Fonts */
  --font-size:        14px;
  --font-size-sm:     12px;
  --font-size-lg:     20px;
}

a {
  color: var(--light-blue);
}

.text-dark:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

button, a {
  outline: none;
}
button:focus, .btn:focus {
  box-shadow: none;
}

/* hide arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.invalid-feedback {
  font-size: var(--font-size);
  color: var(--red-1);
}

.invalid-feedback:after {
  content: '.';
  visibility: hidden;
}


.btn-blue, .btn-blue:hover {
  color: #fff;
  background-color: var(--light-blue);
}
.btn-primary, .btn-primary:hover {
  color: #fff;
  background-color: #0054AE;
  border-color: #0054AE;
  display: flex;
  align-items: center;
}

.btn-outline-warning {
  background: #FCF2E6;
  color: #AA5B00;
  border: 1px solid #AA5B00;
}

.no-border {
  border: none;
}

input {
  background: rgba(33, 150, 243, 0.05);
  border: 2px solid var(--light-blue);
  border-radius: 6px;
}

.nav-back-arrow {
  background: url('./images/keyboard_backspace_24px.svg') no-repeat center center;
  cursor: pointer;
  display: block;
  height: 21px;
  width: 13px;
  margin-right: 10px;
}

.grey-1 {
  color: var(--grey);
}

.font-lg {
  font-size: var(--font-size-lg);
}

.font-md {
  font-size: var(--font-size);
}

.activations-count {
  background: var(--light-grey);
  color: var(--grey-2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  width: fit-content;
}

.activations-count.red {
  background: var(--light-red);
  color: var(--red);
}

.activations-count.green {
  background: var(--light-green);
  color: var(--green);
}

.fw-semibold {
  font-weight: 500;
}

.w-50 {
  width: 50%;
}
.w-40 {
  width: 40%;
}
.w-30{
  width: 30%;
}
.w-20 {
  width: 20%;
}
.w-15 {
  width: 15%;
}
.w-10 {
  width: 10%;
}
.w-5 {
  width: 5%;
}


.table-header {
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  /* Gray/900 */
  color: #171C26;

  background: var(--grey-1);
  backdrop-filter: blur(4px);
}

.table-header.table-row {
  font-size: 12px;
}

.table-row {
  border-bottom: 1px solid var(--light-grey);
  padding: 10px 15px;
  font-size: 14px
}

.table-row > div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.qr-code-icon {
  background: url('./images/qr-code-icon_24px.svg') no-repeat center center;
  cursor: pointer;
  display: block;
  height: 24px;
  width: 24px;
  filter: invert(59%) sepia(11%) saturate(431%) hue-rotate(181deg) brightness(92%) contrast(95%);
}

.triple-dot-menu-icon {
  background: url('./images/triple-dot-icon.svg') no-repeat center center;
  cursor: pointer;
  display: block;
  min-height: 20px;
  height: 100%;
  width: 30px;
}

.edit-icon {
  background: url('./images/edit_icon_24px.svg') no-repeat center center;
  cursor: pointer;
  display: block;
  height: 20px;
  width: 20px;
}

.plus-icon-white {
  background: url('./images/plus-icon_14px.svg') no-repeat center center;
  display: inline-block;
  height: 14px;
  width: 14px;
}
button .plus-icon-white {
  margin-right: 14px;
}

.qr-code-icon:hover {
  filter: none;
}

.pointer {
  cursor: pointer;
}


 /* reactjs-popup styles */
[role='tooltip'].popup-content {
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  padding: 12px 16px;
  z-index: 1100 !important;
}

.hover-arrow {
  color: var(--black-1);
}
[role='tooltip'].hover-content {
  background: var(--black-1);
  color: #FFFFFF;
}

[role='tooltip'].click-content {
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 1px rgb(152 161 179 / 10%), 0px 15px 35px -5px rgb(17 24 38 / 15%), 0px 5px 15px rgb(0 0 0 / 8%);
}

.click-arrow {
  display: none;
}

/* modal */
.modal-body {
  padding: 40px;
}
.modal-content {
  /* box-shadow: 0px 0px 20px 30px rgb(90 177 245 / 2%); */
  border-radius: 16px;
  border: none;
}
.modal-dialog {
  margin: 150px auto !important;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
}
.modal-sm {
  max-width: 430px;
}
.modal-lg {
  max-width: 900px;
}

.modal .title {
  font-size: 24px;
  line-height: 28px;
}

.modal label {
  color: var(--dark-grey);
}

.close-icon {
  background: url('./images/close_24px.svg') no-repeat center center;
  cursor: pointer;
  display: block;
  height: 14px;
  width: 14px;
}

.list-empty-icon {
  background: url('./images/list-empty-icon.svg') no-repeat center center;
  display: block;
  Width: 106.67px;
  Height: 133.33px;
}

.list-empty-desc {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  width: 400px;
  text-align: center;
}

.phone-icon {
  background: url('./images/phone_icon.svg') no-repeat center center;
  display: inline-block;
  height: 16px;
  width: 16px;
}

.status {
  border-radius: 4px;
  font-size: 12px;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 0px 8px;
  height: 24px;
  display: flex;
  width: fit-content;
}

.status:before {
  content: ' ';
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 6px;
}

.status.success {
  background: var(--light-green);
  color: var(--green);
}

.status.success:before {
  background: var(--green);
}

.status.danger {
  background: var(--light-red);
  color: var(--red);
}

.status.danger:before {
  background: var(--red);
}

.status.warning {
  color: #856404;
  background: #fff3cd;
}
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.status.warning:before {
  background: #856404;
}

.spinner {
    position: absolute;
    width: 24px;
    height: 24px;
    margin-left: 100px;
    background: url('./images/loader.png') no-repeat center center;
    animation: spinner .6s linear infinite;
}

.ascending, .descending, .no-sort {
    display: flex;
    user-select: none;
}

.ascending:hover, .descending:hover, .no-sort:hover {
    cursor: pointer;
}

.ascending::after, .descending::after, .no-sort::after {
    content: "";
    width: 16px;
    height: 16px;
    align-self: center;
}

.ascending::after {
    background: url('./images/ascending-sort-icon.svg') no-repeat center center;
}

.descending::after {
    background: url('./images/descending-sort-icon.svg') no-repeat center center;
}

.no-sort::after {
    background: url('./images/no-sort-icon.svg') no-repeat center center;
}

.logout-icon {
    background: url('./images/logout-icon.svg') no-repeat center center;
    width: 22px;
    height: 22px;
    margin-left: 8px;
    cursor:pointer;
}

.d-inherit {
    display: inherit;
}

.search-group {
    display: flex;
    height: 32px;
}

.search-filter {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--font-size);
    border-radius: 6px 0px 0px 6px;
    margin-right: -1px;
    z-index: 2;
    color: var(--grey-2);
    border-color: rgba(70, 79, 96, 0.16);
}

.search-filter-active::after, .search-filter-inactive::after {
    content: "";
    width: 8px;
    height: 6px;
}

.search-filter-active::after {
    background: url('./images/arrow-down-icon.svg') no-repeat center center;
}

.search-filter-inactive::after {
    background: url('./images/arrow-up-icon.svg') no-repeat center center;
}

.search-input {
    width: 280px;
    padding-left: 35px;
    background: url('./images/search-icon.svg') no-repeat 10px center;
    font-size: var(--font-size);
    height: inherit;
    border-radius: 0px 6px 6px 0px;
    border-color: rgba(134, 143, 160, 0.16);
}

.search-input::placeholder, .search-input:focus::placeholder {
    color: var(--grey-3);
}

.search-input:focus::placeholder {
    opacity: 0.5;
}

.tooltip[x-placement="top-start"] > .arrow {
  left: 10px !important;
}

.react-tel-input .form-control {
    font-size: 1rem !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    line-height: 1.5 !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    width: 100% !important;
}

.react-tel-input .form-control:focus,
.react-tel-input .form-control:focus ~ .flag-dropdown {
    border-color: #80bdff !important;
}

.react-tel-input .flag-dropdown.open {
    border-radius: 0.25rem 0 0 0.25rem !important;
}

.react-tel-input .country-list {
    border-radius: 0.25rem !important;
    margin-top: 0.25rem !important;
}

.react-tel-input .flag-dropdown.is-invalid,
.react-tel-input .form-control.is-invalid,
.react-tel-input .form-control.is-invalid:focus,
.react-tel-input .form-control.is-invalid:focus ~ .flag-dropdown {
    border-color: #dc3545 !important;
}
