a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.logo {
    background: url('../styles//images//logo.jpg') no-repeat center center;
    display: block;
    width: 173px;
    height: 67px;
}

 .navbar a {
    color: var(--grey) !important;
    font-size: 18px;
 
}
.navbar {
    background: rgba(33, 150, 243, 0.03);
    border-bottom: 1px solid rgba(33, 150, 243, 0.35);
    padding: 0.5rem 0rem !important;
}


