.login-wrapper {
    max-width: 300px;
}

.login-input {
    font-size: var(--font-size);
    background-color: var(--light-blue-1);
    border-radius: 6px;
    border-width: 2px;
    border-color: var(--light-blue-3);
    height: 45px;
    padding: 12px 20px;
}

.login-input::-webkit-input-placeholder {
    color: var(--grey-3);
}

.login-input:focus {
    color: #000000;
    background-color: var(--light-blue-1);
    border-color: var(--light-blue);
    box-shadow: none;
}

.login-input[value] {
    color: #000000;
}

.login-input:-webkit-autofill,
.login-input:-webkit-autofill:focus,
.login-input:-webkit-autofill::first-line,
.login-input:-webkit-autofill:focus::first-line {
    transition: background-color 5000s ease-in-out 0s;
    font-size: var(--font-size);
}

.form-control.is-invalid {
    border-color: var(--red-1);
    background-image: none;
}

.form-control.is-invalid:focus {
    box-shadow: none;
}

.login-button {
    font-size: 16px;
    height: 45px;
    border-radius: 6px;
    box-shadow: 0px 4px 4px var(--light-blue-2);
}

.login-button:hover {
    background-color: var(--dark-blue);
}

.login-button:disabled {
    background-color: var(--dark-blue);
}